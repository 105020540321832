@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.component-support {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  min-height: fit-content;
  max-width: 1170px;

  @media (max-width: 1300px) {
    padding-left: 72px;
    padding-right: 72px;
  }

  &_mobile {
    display: none;
  }

  h2 {
    font-weight: 800;
    font-size: 60px;
    line-height: 72px;
    color: $CloudBurst;

    @media (max-width: 1300px) {
      font-weight: 800;
      font-size: 50px;
      line-height: 60px;
    }

    @media (max-width: 720px) {
      font-weight: 800;
      font-size: 24px;
      line-height: 29px;
    }
  }

  @include media(720px) {
    min-height: auto;
    display: none;

    &_mobile {
      display: flex;
      border-top: 1px solid $CasperLight;
      border-bottom: 1px solid $CasperLight;
      padding-top: scaledPixels(36);
      padding-bottom: scaledPixels(18);
      padding-left: 24px;
      padding-right: 24px;
      margin-bottom: scaledPixels(130);

      h2 {
        font-weight: 700;
        font-size: 25px;
        line-height: 29px;
      }
    }
  }
}
