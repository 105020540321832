@import '@src/styles/windows.scss';

.pageWrapper{
  box-sizing: border-box;
  width: 100%;
  max-width: 455px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: 183px auto 180px;
  @include mobile{
    max-width: 100%;
    margin-top: 40px;
    margin-bottom: 80px;
    padding: 0 20px;
  }
}

.pageHeader{
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  color: #1C2A40;
  margin-top: 35px;
  margin-bottom: 28px;
  @include mobile{
    margin-top: 20px;
    margin-bottom: 14px;
  }
}

.pageText{
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #1C2A40;
  margin-bottom: 32px;
  @include mobile{
    margin-bottom: 80px;
  }
}

.pageButton{
  width: 200px;
  background: #1C2A40;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0 7px 0;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  color: #FEFEFE;
  cursor: pointer;
}
