@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';

.container {
  margin-bottom: 100px;
}

.top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 18px;
}

.edgePoint {
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: $CloudBurst;

  &_selected {
    color: $WhiteLight;
  }
}

.range {
  height: 6px;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .handle {
    position: absolute;
    top: -12px;
    border-radius: 50%;
    background-color: $WhiteLight;
    cursor: grab;
    z-index: 1;

    &_popup {
      position: absolute;
      top: 30px;
      border-radius: 30px;
      padding: 6px 20px;
      @include text23;
      font-weight: 700;
      line-height: 29px;
      white-space: nowrap;
      color: $WhiteLight;
      background-color: $CloudBurst;
      cursor: auto;

      &_invisible {
        visibility: hidden;
      }
    }
  }

  .point {
    position: relative;
    background-color: $CloudBurst;

    &:hover {
      cursor: pointer;
    }

    &Inner {
      background-color: $WhiteLight;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
    }

    &_selected {
      background-color: $WhiteLight;
    }

    &:first-of-type {
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
    }

    &:last-of-type {
      border-top-right-radius: 3px;
      border-bottom-right-radius: 3px;
    }
  }
}

@media (max-width: 1300px) {

  .edgePoint {
    font-size: 25px;
    line-height: 30px;
  }

  .range {

    .handle {

      &_popup {
        font-size: 25px;
        line-height: 35px;
      }
    }
  }
}

@include mobile {

  .edgePoint {
    font-size: 12px;
    line-height: 14px;
  }

  .range {
    height: 3px;

    .handle {
      top: -6px;

      &:before {
        content: '';
        position: absolute;
        left: -8px;
        top: -8px;
        width: 30px;
        height: 30px;
      }

      &_popup {
        font-size: 12px;
        line-height: 14px;
        top: 15px;
        padding: 4px 8px;
      }
    }
  }

  .top {
    margin-bottom: 10px;
  }
}
