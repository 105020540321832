@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';

.button {
  border: none;
  margin: 0;
  padding: 0;
  background-color: transparent;
}

.item {
  border-radius: 6px;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  gap: 18px;

  &:hover {
    background-color: $WhiteLilac;
  }

  &Icon {
    width: 42px;
  }

  &TextContainer {
    display: flex;
    flex-direction: column;
    gap: 3px;
  }

  &Title {
    @include font;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: $desaturatedBlue;
    white-space: nowrap;
  }

  &Description {
    @include font;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $Waterloo;
    white-space: nowrap;
  }
}

