@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.component-sign-up-steps {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  //min-height: 870px;
  padding-top: 99px;
  max-width: 1170px;

  @media (max-width: 1300px) {
    padding-left: 72px;
    padding-right: 72px;
  }

  @media (max-width: 720px) {
    max-width: 100vw;
    overflow: hidden;
  }

  h2 {
    margin-bottom: 6px;
    font-size: 60px;
    line-height: 72px;
    font-weight: 800;
    color: $CloudBurst;

    @media (max-width: 1300px) {
      font-weight: 800;
      font-size: 50px;
      line-height: 60px;
    }

    @media (max-width: 720px) {
      font-weight: 700;
      font-size: 25px;
      line-height: 29px;
    }
  }

  &_mobile {
    display: none;
  }

  @include media(720px) {
    min-height: auto;
    display: none;

    &_mobile {
      display: flex;
      border-top: 1px solid $CasperLight;
      padding: scaledPixels(36) 24px scaledPixels(18);
    }
  }

  &__description {
    font-weight: normal;
    font-size: 20px;
    line-height: 24px;
    color: $ShuttleGray;
    margin-bottom: 53px;
  }

  &__content-block {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;


    &__item {
      width: 330px;
      display: flex;
      flex-direction: column;
      margin-bottom: 95px;

      &__header-block {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 26px;

        &__image {
          width: 74px;
        }

        &__title {
          font-weight: bold;
          font-size: 24px;
          line-height: 29px;
          color: $CloudBurst;

          margin-left: 27px;
          padding-left: 23px;
          border-left: 1.1px solid $CloudBurst;
        }
      }

      &__text{
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $ShuttleGray;
      }
    }
  }
}
