@import '@src/styles/colors.scss';
@import '@src/styles/windows.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.Content-Top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 26px;
  border-bottom: 1px solid #AEC2D4;
  margin-bottom: 35px;

  @include mobile {
    padding-bottom: 14px;
    margin-bottom: 15px;
  }
}

.addedLimitBtn {
  width: 40px;
  height: 40px;
  margin-left: auto;
  margin-top: -8px;
  color: #F2466E;

  @include mobile {
    width: 18px;
    height: 18px;
    margin-top: -3px;
  }
}

.profile-header {
  position: fixed;
  width: 100%;
  height: 70px;
  border-bottom: 1px solid $LinkWater;
  background-color: #F2F4F9;
  z-index: 1000;
  padding: 0 10px;
  box-sizing: border-box;
  @include tablet{
    //display: none;
  }

  .Button_status_actived {
    background-color: #1C2A40;
  }

  @media screen and (max-width: 720px) {
    position: unset;
    height: scaledPixels(70);
    background-color: transparent;
    margin-top: scaledPixels(70);
    border-bottom: 0;
  }

  &_isHelpSecondScreen,
  &_isHelpThirdScreen {
    z-index: 1010 !important;
    border-bottom: 1px solid rgba($LinkWater, 0.1) !important;
    display: flex;
  }

  &-Overlay {
    position: absolute;
    left: -100px;
    background-color: $CloudBurst;
    opacity: 0.7;
    width: 120%;
    height: 102%;
  }

  .Content-Block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: 100%;

    @include tablet {
      width: 100%;
      flex-direction: column;
      align-items: unset;
      padding: 0;
    }

    .logo {
      cursor: pointer;

      @include mobile {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }

    .auth-icons {
      display: flex;
      align-items: center;
    }

    .profile-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 8.5px;

      border-right: 1px solid $CloudBurst;

      .Icon {
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 31.4px;
        }
      }
    }

    .logout-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-left: 8.5px;

      @include tablet {
        margin: 0;
      }

      .text {
        margin-left: 7.5px;

        font-style: normal;
        font-weight: bold;
        font-size: 14px;
        line-height: 17px;
        color: $EbonyClay;


        @include tablet {
          display: block;
          @include text25;
          color: #FFFFFF;
          font-weight: normal;
        }
        @include mobile {
          @include text12
        }
      }

      .Icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 31.4px;
        height: 31.4px;
        padding-right: 1px;
        background-color: $EbonyClay;
        border-radius: 50%;

        @include mobile {
          width: 11px;
          height: 11px;
        }
        img {
          width: 16px;

          @include tablet {
            width: 23px;
          }

          @include mobile {
            width: 11px;
          }
        }
      }
    }
  }

  &-CreditLineContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 5;

    @include tablet {
      align-items: start;
      display: grid;
      grid-template-columns: 7fr 1fr;
    }

    .Button {

      &-Content {
        flex-direction: row;
        align-items: center;
        // padding: 4px 12px;

        svg, img {
          margin-right: 5px;
        }
      }
    }
  }

  &-CreditLineTitle {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: $CloudBurst;

    &_isHelpSecondScreen,
    &_isHelpThirdScreen {
      color: $WhiteDark;
    }
  }

  &-CreditLineDescription {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    text-align: center;
    color: $GullGray;
    margin-top: 6.5px;

    @include tablet {
      @include text21;
      margin-top: 21px;
      line-height: inherit;
    }

    @include mobile {
      @include text10;
      margin-top: 10px;
    }

    &_isHelpSecondScreen,
    &_isHelpThirdScreen {
      display: none;
    }
  }

  &-CreditLine {
    width: 746px;
    margin: 25px 20px 0 20px;

    @include inch13() {
      width: 500px;
    }

    @include tablet {
      width: 100%;
      margin: 0;
      + img{
        display: inline-block;
        margin-left: 20px;
        margin-top: -4px;
      }
    }
  }

  &-CreditLineSections {

    display: flex;
    flex-direction: row;
    height: 8px;
    width: 100%;
    border-radius: 5px;
    background-color: $CatskillWhite2;
    position: relative;

    &_isHelpSecondScreen,
    &_isHelpThirdScreen {
      z-index: 1010;
      background-color: #929AB2;
    }

    @keyframes moving {

      to {
        background-position-x: 26px;
      }
    }

    &_onChecking {
      animation: 2s moving linear infinite;
    }

    @include tablet {
      background-color: inherit;
      display: flex;
      justify-content: space-between;
      height: auto;
    }
  }

  &-CreditLineHelpMessage {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 30px;
    text-align: left;

    font-family: Flow, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;

    color: $CasperLight;

    svg {
      margin-bottom: 10px;
    }

    &_First {
      align-items: flex-end;
      left: -270px;
      width: 384px;
      @include tablet{
        font-size: 13px;
        align-items: initial;
        left: 0;
        width: 200px;
        top: 50px;
        svg{
          transform: scale(-1, 1) rotate(-22deg);
          height: 40px;
          width: 40px;
        }
      }
      @include smallMobile{
        font-size: 10px;
      }
    }

    &_Second {
      align-items: flex-end;
      width: 298px;
      right: 20px;
      @include tablet{
        text-align: right;
        width: 155px;
        top: -10px;
        right: -20px;
        font-size: 13px;
        svg{
          width: 30px;
          height: 60px;
          transform: rotate(-62deg);
          position: relative;
          top: 17px;
        }
      }
      @include smallMobile{
        font-size: 10px;
      }
    }

    &_MobileTwo{
      display: none;
      @include tablet{
        display: block;
        top: -85px;
        text-align: right;
        width: 245px;
        right: -40px;
        font-size: 13px;
        svg{
          width: 20px;
          height: 13px;
          position: absolute;
          right: 0;
          top: 50px;
          transform: rotate(110deg)
        }
      }
      @include smallMobile{
        font-size: 10px;
      }
    }

    &_MobileThree{
      display: none;
      @include tablet{
        display: block;
        top: -85px;
        text-align: right;
        width: 245px;
        right: -40px;
        font-size: 13px;
        svg{
          width: 20px;
          height: 13px;
          position: absolute;
          right: 0;
          top: 50px;
          transform: rotate(110deg)
        }
      }
      @include smallMobile{
        font-size: 10px;
      }
    }

    &_Third {
      width: 328px;
      right: -40px;
      top: 135px;
      white-space: break-spaces;

      svg {
        transform: rotate(-45deg) translate(155px, 115px);
      }
    }

    &_Fourth {
      align-items: flex-end;
      width: 350px;
      left: -300px;
      @include tablet{
        font-size: 13px;
        left: 0;
        top: 0;
        text-align: left;
        width: 90%;
        svg{
          width: 31px;
          height: 83px;
          transform: rotate(-60deg);
        }
      }
      @include smallMobile{
        top: -30px;
        font-size: 10px;
        svg{
          position: relative;
          bottom: -20px;
        }
      }
    }

    &_Fifth {
      width: 328px;
      right: -10px;
      top: 100px;
      white-space: break-spaces;

      svg {
        transform: rotate(-45deg) translate(155px, 115px);
      }
    }
  }

  &-CreditLineSection {
    width: calc(100% / 10);
    height: 100%;
    border-left: 1px solid #FFFFFF;

    @include tablet {
      width: 27px;
      height: 27px;
      border-radius: 50px;
      background-color: #E4E8F2;
      border: none;
    }

    @include mobile {
      width: 13px;
      height: 13px;
    }

    &_isHelpSecondScreen,
    &_isHelpThirdScreen {
      border-left: 1px solid #FFFFFF;
    }

    &:first-of-type {
      border-left: none;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      @include tablet {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
      }
    }

    &:last-of-type {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;

      @include tablet {
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
      }
    }

    &_status {

      &_first {
        background-color: $Illusion;
      }

      &_second {
        background-color: $BrinkPink;
      }
    }
  }
}
