@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 100px 0;
  width: 100%;
  max-width: 1170px;
  min-height: unset;
}

.title {
  @include title60Bold;
  line-height: 72px;
  color: $CloudBurst;
}

.caption {
  @include text20;
  font-weight: 400;
  line-height: 24px;
  color: $ShuttleGray;
  margin-top: 24px;
}

.contentContainer {
  margin-top: 100px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 50px;
  width: 100%;
}

.list {
  list-style: none;
  padding: 0;
  margin: 0 auto auto 0;
  display: flex;
  flex-direction: row;
  gap: 18px;
  flex-wrap: wrap;
}

.listItem {
  width: 188px;
  height: 188px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  background-color: $AthensGray;
  border: 1px solid $CatskillWhite3;
  border-radius: 16px;
  padding: 30px 24px;
  box-sizing: border-box;
}

.itemText {
  @include text13;
  line-height: 16px;
  color: $CloudBurst;
  text-align: center;
}

.itemPic {
  width: 120px;
  height: 62px;
  display: flex;
  justify-content: center;
  align-items: center;

  &Visa {
    width: 99px;
  }

  &MasterCard {
    width: 76px;
  }

  &Mir {
    width: 110px;
  }

  &BankTransfer {
    width: 55px;
  }

  &QR {
    width: 55px;
  }

  &SBP {
    width: 96px;
  }
}

.mainImage {
  width: 482px;
  height: 425px;
}

@media (max-width: 1300px) {

  .section {
    padding: 70px 72px;
  }

  .contentContainer {
    margin-top: 45px;
  }

  .list {
    gap: 16px;
  }

  .mainImage {
    display: none;
  }

  .listItem {
    width: calc(50% - 8px);
    height: 238px;
    padding: 38px 24px;
    background-color: $CatskillWhite3;
  }

  .itemText {
    font-size: 25px;
    line-height: 30px;
  }

  .title {
    font-size: 50px;
    line-height: 60px;
  }

  .caption {
    font-size: 25px;
    line-height: 30px;
  }

  .itemPic {
    width: 150px;
    height: 80px;

    &Visa {
      width: 139px;
    }

    &MasterCard {
      width: 120px;
    }

    &Mir {
      width: 148px;
    }

    &BankTransfer {
      width: 75px;
    }

    &QR {
      width: 75px;
    }

    &SBP {
      width: 132px;
    }
  }
}

@include mobile {

  .section {
    padding: 34px 24px;
  }

  .title {
    @include font;
    font-size: 24px;
    font-weight: 800;
    line-height: 29px;
  }

  .caption {
    @include font;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-top: 21px;
  }

  .contentContainer {
    margin-top: 21px;
  }

  .list {
    gap: 8px;
  }

  .listItem {
    width: calc(50% - 4px);
    height: 112px;
    background-color: $CatskillWhite3;
    padding: 12px 8px;
    gap: 6px;
  }

  .itemText {
    @include font;
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
  }

  .itemPic {
    width: 74px;
    height: 45px;

    &Visa {
      width: 66px;
    }

    &MasterCard {
      width: 57px;
    }

    &Mir {
      width: 70px;
    }

    &BankTransfer {
      width: 35px;
    }

    &QR {
      width: 35px;
    }

    &SBP {
      width: 62px;
    }
  }
}
