$Selago: #f3f7fd;
$BrightGray: #3b424c;
$Gravel: #48444a;
$Black: #000000;
$Gray: gray;
$LightGray: #616161;
$WhiteBase: #FFFFFF;
$WhiteLight: #FEFEFE;
$WhiteDark: #FDFDFD;
$WhiteLilac: #F3F5FA;
$CloudBurst: #1c2a40;
$CodGray: #111111;
$Midnight: #001528;
$RockBlue: #9db7cb;
$SlateGray: #6B798E;
$SlateGray2: #616F85;
$SlateGray3: #6B798F;
$LinkWater: #e0e7f4;
$Selago: #f9fbfe;
$CardMainWhite: #FAFBFD;
$CatskillWhite1: #F9FAFC;
$CatskillWhite2: #E4E8F2;
$CatskillWhite3: #E3E7F1;
$CatskillWhite4: #F2F4F9;
$CatskillWhite5: #EBEEF5;
$AthensGray: #F6F7F9;
$Alabaster: #fafafa;
$Casper: #AEC2D4;
$CasperLight: #AFC3D5;
$BaliHai: #7E94A7;
$Zircon: #FCFDFF;
$Ghost: #CACED9;
$ShuttleGray: #585F69;
$BrinkPink: #53D5C9;
$FrenchRose: #53D5C9;
$CoralTree: #a36271;
$Mandy: #53D5C9;
$ChestnutRose: #CC4E6C;
$SeaPink: #EEA8B8;
$greyRed: #3A978E;
$TickleMePink: #3A978E;
$TickleMePinkLight: #53D5C9;
$AlizarinCrimson: #E31E24;
$Error: #F76083;
$Illusion: #F5B3C2;
$Lynch: #67788F;
$GullGray: #939BB3;
$Vulcan: #0F1721;
$Seashell: #f1f1f1;
$EbonyClay: #272F3C;
$Mischka: #CFD2DB;
$SantasGray: #929ab2;
$PigeonPost: #c1c9df;
$Waterloo: #7A8091;
$Botticelli: #CAD6E4;
$Geyser: #D3D8E3;
$Comet: #546178;
$BaliHai: #8C9BB4;
$PickledBluewood: #384561;
$desaturatedBlue: #1C2A40;
