@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  &:last-of-type {
    margin-right: 0;
  }

  &:hover {
    cursor: pointer;
  }
}

.link {
  @include font;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $desaturatedBlue;

  .container:hover & {
    color: $Error;
  }
}

.topContainer {
  display: flex;
  align-items: center;
  gap: 4px;
}

.title {
  @include font;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: $desaturatedBlue;

  .container:hover & {
    color: $Error;
  }
}

.arrowIcon {
  width: 7px;
  height: 5px;
  transform: rotate(180deg);

  .container:hover & {
    transform: none;
  }
}

.nestedMenuList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: none;

  .container:hover & {
    position: absolute;
    display: block;
    padding: 15px;
    border-radius: 8px;
    background-color: $CatskillWhite1;
    box-shadow: 0 0 15px $CasperLight;
    top: 73px;
  }
}
