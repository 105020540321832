@import '@src/styles/colors.scss';
@import '@src/styles/windows.scss';
@import '@src/styles/mixins.scss';

.Layout-Profile {

  &-Central-Block {
    padding-top: 70px;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    height: 100%;

    position: relative;
    transition: left 0.3s;
    @include tablet{
      padding-top: 0;
      position: static;
    }

    .profile-header {
      display: none;
    }
    @include tablet {
      left: 0;
      overflow: hidden;

      .profile-header {
        margin-top: 0;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        height: auto;
        overflow: hidden;
        padding: 59px 72px;
        background-color: #F2F4F9;
        border: none;

        &.isOpen {
          display: flex;
          position: fixed;
        }
      }
    }

    @include mobile {
      .profile-header {
        padding: 27px 24px;
      }
    }
  }

  &-Content-Block {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    height: 100%;
    @include tablet{
      .Sign-Up{
        .Control{
          display: flex;
          margin-top: 40px;
        }
        .Input-Wrapper-Error{
          position: static;
        }
      }
    }
  }
}

.isHelpScreen{
  @include tablet{
      display: flex !important;
      position: fixed !important;
      background-color: transparent !important;
      height: 0 !important;
      overflow: unset !important;
      z-index: 1200;
    .profile-header-Overlay{
      display: none;
    }
    .Content-Block{
      justify-content: flex-start;
    }
    .auth-icons{
      display: none !important;
    }
  }
}


