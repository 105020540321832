@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.component-footer {
  background: conic-gradient(from 90deg at 0% 105%, $WhiteBase, $CloudBurst);
  padding: 64px 0 80px 0;

  @include media(1300px) {
    padding-top: 50px;
    padding-bottom: 70px;
  }

  @include media(720px) {
    padding-top: 24px;
    padding-bottom: 34px;
  }

  & .Width-Wrapper {
    @include media(1300px) {
      width: auto;
      margin: 0 74px;
    }

    @include media(720px) {
      margin: 0 24px;
      padding: 0;
    }
  }

  &-Jivo-Block{
    background-color: #54657a;
    padding: 5px 22px;
    border-radius: 10px;
    margin-left: 26px;
    cursor: pointer;
    display: inline-block;
  }

  &-Chat-text{
    font-weight: bold;
    font-size: 14px;
    line-height: 17px;
    color: #FDFDFD;
    display: inline;
  }

  @include media(720px) {
    background: $CloudBurst;
    margin-bottom: scaledPixels(150);
  }

  &__layerinfo{
    margin-top: 18px;
    font-size: 14px;
    line-height: 17px;
    text-align: justify;
    color: #7E94A7;

    @include media(1300px) {
      margin-top: 60px;
      font-weight: 400;
      font-size: 21px;
      line-height: 25px;
    }

    @include media(720px) {
      text-align: left;
      margin-top: 28px;
      font-size: 10px;
      line-height: 12px;
    }
  }

  & .copyright {
    @include media(1300px) {
      display: none;
    }
  }

  &__header-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    padding-bottom: 25px;
    border-bottom: 1.1px solid $CatskillWhite3;

    @include media(1300px) {
      padding-bottom: 50px;
    }

    @include media(720px) {
      padding-bottom: 20px;

      img {
        width: 142px;
      }
    }

    &__img {
      height: 34px;
    }

    &__item {

      &_links-block {
        display: flex;
        @include media(1300px) {
          display: none;
        }
      }
    }
  }

  &__link {
    cursor: pointer;
    font-weight: normal;
    font-size: 13px;
    line-height: 16px;
    color: $CatskillWhite3;

    &:not(:last-child) {
      margin-right: 53px;
    }
  }

  &__mobile-links-block {
    display: none;
    line-height: 1;

    &__item {
      color: $SlateGray3;
      display: inline-block;

      &.component-footer__link {
        margin-right: 32px;
        padding-right: 32px;
        border-right: 1px solid $SlateGray;
        font-weight: 400;
        font-size: 21px;
        line-height: 25px;
        color: #7E94A7;
        text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);

        @include media(720px) {
          font-size: 10px;
          line-height: 12px;
          margin-right: 12px;
          padding-right: 12px;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    @include media(1300px) {
      margin-top: 14px;
      display: flex;
      flex-wrap: wrap;
      gap: 13px 0;
    }

    @include media(1300px) {
      gap: 6px 0;
    }
  }

  & .mobile-copyright {
    display: none;

    @include media(1300px) {
      display: block;
      font-weight: 400;
      font-size: 21px;
      line-height: 25px;
      color: #E4E8F2;
      margin-top: 60px;
    }

    @include media(720px) {
      margin-top: 28px;
      font-size: 10px;
      line-height: 12px;
    }
  }

  &__header-bottom {
    margin-top: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @include media(1300px) {
      width: 100%;
    }

    @include media(720px) {
      margin-top: 10px;
    }

    &__title {
      font-weight: 700;
      font-size: 14px;
      line-height: 17px;
      color: #FDFDFD;
      margin-right: 26px;
      display: inline-block;

      @include media(1300px) {
        font-size: 24px;
        line-height: 29px;
        margin: 0;
      }

      @include media(720px) {
        font-size: 11px;
        line-height: 13px;
      }
    }

    &__item {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: $CatskillWhite3;
      display: flex;
      align-items: center;

      @include media(720px) {
        display: none;
      }

      &_social-block {
        @include media(1300px) {
          width: 100%;
          justify-content: flex-end;
          align-items: center;
          gap: 50px;
        }

        @include media(720px) {
          display: flex;
          align-items: center;
          gap: 22px;
        }
      }
    }
  }

  &__social-link {
    margin-left: 30px;
    margin-top: 5px;

    @include media(1300px) {
      margin: 0;
    }

    &__img {
      height: 22px;

      @include media(1300px) {
        height: unset;
        width: 34px;
      }

      @include media(720px) {
        width: 18px;
      }
    }
  }

  &__partners-block {
    margin-top: 18px;

    @include media(1300px) {
      margin-top: 60px;
    }

    @include media(720px) {
      margin-top: 28px;
    }

    &__title {
      width: 100%;
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      text-align: center;
      color: #7E94A7;
      display: inline-block;

      @include media(1300px) {
        font-size: 21px;
        line-height: 25px;
        text-align: left;
        color: #E4E8F2;
      }

      @include media(720px) {
        text-align: left;
        color: $CatskillWhite2;
        font-size: 10px;
        line-height: 12px;
      }
    }

    &__content {
      margin-top: 42px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      gap: 20px 0;

      @include media(1300px) {
        margin-top: 24px;
        display: grid;
        grid-template-columns: min-content auto min-content;
      }

      @include media(720px) {
        margin-top: 13px;
      }
    }

    &__partner-link {

      @include media(1300px) {

        &:nth-of-type(2) {

          img {
            margin: 0 auto;
          }
        }

        &:last-of-type {

          img {
            margin-left: auto;
          }
        }
      }

      @include media(720px) {

        &:nth-of-type(1) {

          & img {
            width: 77px;
          }
        }

        &:nth-of-type(2) {

          & img {
            width: 30px;
          }
        }

        &:nth-of-type(3) {

          & img {
            width: 84px;
          }
        }

        &:nth-of-type(4) {

          & img {
            width: 47px;
          }
        }

        &:nth-of-type(5) {

          & img {
            width: 80px;
          }
        }

        &:nth-of-type(6) {

          & img {
            width: 62px;
          }
        }
      }

      &__img {

        display: none;

      }

      &__img_visible {
        display: block;
      }
    }
  }
}

.Jivo-Block{
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  color: #FDFDFD;
  height: 38px;
  width: 140px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #53D5C9;
  border-radius: 10px 10px 10px 0;

  @include media(1300px) {
    font-size: 24px;
    line-height: 29px;
    width: unset;
    height: unset;
    padding: 14px 28px;
  }

  @include media(720px) {
    font-size: 11px;
    line-height: 13px;
    padding: 6px 12px;
  }
}

.Chat-text{
  display: inline-block;
}
