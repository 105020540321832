@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.feedback {
  box-sizing: border-box;
  background-color: $SlateGray2;
  width: 100%;
  min-height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px;

  @include media(1300px) {
    padding: 0 74px;
  }

  @include media(720px) {
    padding: 0 24px;
  }

  &__wrapper {
    box-sizing: border-box;
    max-width:  515px;

    @include media(1300px) {
      max-width: none;
      width: 100%;
    }
  }

  &__title {
    margin-top: 33px;
    margin-bottom: 9px;
    font-weight: 800;
    font-size: 50px;
    line-height: 60px;
    text-align: center;
    color: $WhiteBase;

    @include media(1300px) {
      margin-top: 38px;
      margin-bottom: 18px;
      text-align: left;
    }

    @include media(720px) {
      font-size: 24px;
      line-height: 29px;
      text-align: left;
      margin: 18px 0 8px;
    }
  }

  &__text {
    font-size: 16px;
    line-height: 19px;
    text-align: center;
    color: $WhiteBase;
    margin-bottom: 23px;
    white-space: pre-line;

    @include media(1300px) {
      font-size: 25px;
      line-height: 30px;
      text-align: left;
      margin-bottom: 36px;
    }

    @include media(720px) {
      font-size: 12px;
      line-height: 14px;
      text-align: left;
      margin-bottom: 17px;
    }
  }

  &__form {
    display: flex;
    justify-content: space-between;

    .Input {
      width: auto;
      flex: 1;

      .Input-Inline {
        padding: 4.5px 6px 5px 12px;
        margin-right: 21px;
      }
    }

    .Button {
      width: fit-content;
      padding: 7px 27px;
      color: $WhiteDark;
    }
  }

  &__description {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: $WhiteBase;
    opacity: 0.5;
    margin: 25px 0 51px 0;

    @include media(1300px) {
      font-size: 21px;
      line-height: 29px;
      text-align: left;
    }

    @include media(720px) {
      font-size: 10px;
      line-height: 14px;
      margin: 12px 0 16px;
    }

    a {
      color: $WhiteBase;
      text-decoration-line: underline;
    }
  }

  @include media(1300px) {
    margin-bottom: 0;

    .Width-Wrapper {
      max-width:  none;
    }

    &__form {

      .Input {
        width: 50%;

        span, input {
          padding: 0;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;

          @include media(1300px) {
            font-size: 25px;
            line-height: 30px;
          }

          @include media(720px) {
            font-size: 12px;
            line-height: 14px;
          }
        }

        .Input-Inline {
          margin-right: 22px;
          padding: 6px 12px;

          @include media(1300px) {
            margin-right: 14px;
            padding: 14px 22px;
          }

          @include media(720px) {
            margin-right: 8px;
            padding: 6px 10px;
          }
        }
      }

      .Button {
        width: 50%;
        border-radius: 100px;
        padding: 8px 26px;
        font-weight: 700;
        font-size: 14px;
        line-height: 17px;
        justify-content: center;

        @include media(1300px) {
          font-size: 24px;
          line-height: 29px;
          padding: 14px 50px;
        }

        @include media(720px) {
          font-size: 12px;
          line-height: 14px;
          padding: 6px 20px;
        }
      }
    }
  }
}
