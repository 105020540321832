@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.section {
  background-color: $BrinkPink;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-height: unset;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;

  @include tablet {
    padding: 0 72px;
  }

  @include mobile {
    padding: 0 24px;
  }
}

.container {
  max-width: 1170px;
  margin: 70px auto 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  @include title60Bold;
  line-height: 72px;
  color: $WhiteDark;
  align-self: flex-start;
}

.contentContainer {
  margin-top: 32px;
  margin-bottom: 80px;
  display: flex;
  flex-direction: row;
  gap: 50px;
}

.column {
  width: 50%;
}

.rangeSubtitleContainer {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.subtitle {
  @include text28;
  font-weight: 900;
  line-height: 34px;
  color: $WhiteBase;
  margin-bottom: 28px;
}

.infoButton {
  font-size: 25px;
  font-weight: 700;
  line-height: 33px;
  border-radius: 50%;
  color: $WhiteLight;
  background-color: $greyRed;
  padding: 5px 14px;
  width: 40px;
  height: 40px;
  order: 3;
  display: none;
}

.text {
  @include text16;
  font-weight: 400;
  line-height: 19px;
  color: $WhiteBase;

  &:first-of-type {
    margin-bottom: 42px;
  }

  &:last-of-type {
    margin-top: auto;
  }
}

@media (max-width: 1300px) {

  .section {
    padding: 60px 74px 70px;
  }

  .container {
    margin-top: 60px;
    margin-bottom: 70px;
  }

  .title {
    @include title50Bold;
    line-height: 60px;
    color: $WhiteBase;
  }

  .column {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .text {
    @include text25;
    font-weight: 400;
    line-height: 30px;
    color: $WhiteBase;

    &:first-of-type {
      order: 0;
      margin-bottom: 42px;
    }

    &:last-of-type {
      order: 1;
      margin-bottom: 57px;
    }
  }

  .range {
    order: 3;
    margin-bottom: 58px;
  }

  .subtitle {
    @include title32Bold;
    font-weight: 900;
    line-height: 38px;
    order: 2;
    margin-bottom: 20px;
  }

  .rangeSubtitleContainer {
    order: 2;
  }

  .subtitleBottom {
    order: 0;
    margin-bottom: 26px;
  }

  .contentContainer {
    max-width: 1170px;
    margin: 30px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 45px;
  }

  .popup{
    position: absolute;
    top: 200px;
    right: 74px;
    bottom: 400px;
    left: 74px;
    border-radius: 20px 20px 0 0;
    background-color: $CatskillWhite5;
    padding: 40px calc((72 * 100vw) / 720) 45px;

    &Title {
      margin-top: 0;
      margin-bottom: 28px;
      margin-right: calc(((72 * 100vw) / 720) + 10px);
      @include title32Bold;
      line-height: 38px;
      color: $CloudBurst;
    }

    &Text {
      font-size: 25px;
      font-weight: 400;
      line-height: 30px;
      color: $CloudBurst;
    }

    &Button {
      position: absolute;
      top: 40px;
      right: calc((72 * 100vw) / 720);
      border-radius: 50%;
      background-color: $CatskillWhite5;
      padding: 0;
    }

    &CloseIcon {
      width: 45px;
      height: 45px;

      path:not(:first-of-type) {
        stroke: $WhiteLight;
      }
    }
  }

  .popupOverlay.popupOverlay {
    position: fixed !important;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0 !important;
  }
}

@include mobile {

  .title {
    font-size: 24px;
    line-height: 29px;
  }

  .subtitle {
    font-size: 15px;
    line-height: 18px;
  }

  .section {
    padding: 28px 24px 33px;
  }

  .contentContainer {
    margin-top: 14px;
    gap: 0;
  }

  .container {
    margin-top: 0;
    margin-bottom: 0;
  }

  .infoButton {
    display: block;
    width: 20px;
    height: 20px;
    font-size: 12px;
    line-height: 14px;
    padding: 1px;
  }

  .popup{
    position: absolute;
    top: unset;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 20px 20px 0 0;
    background-color: $CatskillWhite5;
    padding: 18px 24px 18px;

    &Title {
      margin-top: 0;
      margin-bottom: 14px;
      margin-right: 90px;
      font-weight: 800;
      font-size: 15px;
      line-height: 18px;
      color: $CloudBurst;
    }

    &Text {
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: $CloudBurst;
    }

    &Button {
      position: absolute;
      top: 18px;
      right: 24px;
      border-radius: 50%;
      background-color: $CatskillWhite5;
      padding: 0;
    }

    &CloseIcon {
      display: block;
      width: 22px;
      height: 22px;

      path:not(:first-of-type) {
        stroke: $WhiteLight;
      }
    }
  }

  .popupOverlay.popupOverlay {
    position: fixed !important;
    left: 0;
    top: 0;
    right: 0;
    bottom: calc((150 * 100vw) / 720) !important;
    background-color: rgba(28, 42, 64, 0.5);
    z-index: 9;
  }

  .text {
    font-size: 12px;
    line-height: 14px;

    &:first-of-type {
      margin-bottom: 18px;
    }

    &:last-of-type {
      display: none;
    }
  }
}
