@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';

.list {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

.item {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  gap: 14px;
  margin-bottom: 30px;
}

.stars {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;
}

.star {
  width: 20px;
  height: 20px;

  & path {
    stroke: $greyRed;
    fill: $greyRed;
  }

  &_active path {
    stroke: $WhiteLight;
    fill: none;
  }

  &_filled path {
    fill: $WhiteLight;
  }
}

.text {
  @include text16;
  font-weight: 500;
  line-height: 19px;
  white-space: normal;
  color: $greyRed;

  &_active {
    color: $WhiteBase;
  }
}

@media (max-width: 1300px) {

  .item {
    display: none;
    border-radius: 20px;
    background-color: $greyRed;
    flex-direction: column;
    padding: 40px 28px;
    gap: 20px;
  }

  .currentItem {
    display: flex;
  }

  .text {
    font-size: 25px;
    font-weight: 500;
    line-height: 30px;
  }

  .star {
    width: 40px;
    height: 40px;
  }
}

@include mobile {

  .item {
    padding: 20px 14px;
    gap: 10px;
    margin-bottom: 0;
  }

  .stars {
    gap: 3px;
  }

  .star {
    width: 19px;
    height: 19px;
  }

  .text {
    font-size: 12px;
    line-height: 14px;
  }
}
