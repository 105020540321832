@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';
@import '@src/styles/windows.scss';

.section {
  flex-direction: column;
  box-sizing: border-box;
  margin: 78px auto 0;
  max-width: 1170px;
  padding: 70px 0;
  position: relative;

  @include tablet {
    padding: 0 72px;
  }

  @include mobile {
    padding: 0 24px;
  }
}

.topContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 40px;

  &LeftColumn {
    margin-top: 35px;

    @media screen and (max-width: 720px) {
      margin-top: 0;
    }
  }
}

.title {
  @include font;
  font-size: 38px;
  font-weight: 900;
  line-height: 46px;
  color: $CloudBurst;
}

.caption {
  @include font;
  font-size: 18px;
  font-weight: 400;
  line-height: 22px;
  margin-top: 29px;
  margin-bottom: 41px;
  color: $LightGray;
}

.imageContainer {
  box-sizing: border-box;
  flex-shrink: 0;
  width: 530px;
  height: 425px;
  display: flex;
  justify-content: flex-end;
}

.fastImage {
  width: 530px;
  height: 425px;
}

.safeImage {
  width: 418px;
  height: 425px;
}

.comfortableImage {
  width: 467px;
  height: 425px;
}

.reliableImage {
  width: 490px;
  height: 425px;
}

.advantages {
  margin: 73px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  list-style-type: none;
  padding: 0;
}

.advantage {
  box-sizing: border-box;
  padding: 20px 26px;
  border-left: 1px solid $CasperLight;
  width: 292px;
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    height: 8px;
    width: 0;
    background-color: $BrinkPink;
  }

  &:last-of-type {
    border-right: 1px solid $CasperLight;
  }

  &Icon {
    display: none;
  }

  &Title {
    @include font;
    font-size: 24px;
    font-weight: 900;
    line-height: 29px;
    color: $CloudBurst;
    margin: 0;
  }

  &Text {
    @include font;
    font-size: 15px;
    font-weight: 400;
    line-height: 18px;
    margin-top: 12px;
    color: $LightGray;
  }

  &Active {
    background-color: $CardMainWhite;

    &:after {
      width: 292px;
      transition: width 5s linear;
    }
  }
}

.featuresTitle {
  @include font;
  font-size: 38px;
  font-weight: 800;
  line-height: 46px;
  margin-top: 49px;
  color: $CloudBurst;
  align-self: flex-start;
}

.features {
  list-style-type: none;
  padding: 0;
  margin: 24px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.feature {
  box-sizing: border-box;
  background-color: $CatskillWhite3;
  border-radius: 8px;
  width: 222px;
  height: 180px;
  padding: 35px 18px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &Text {
    @include font;
    font-size: 16px;
    font-weight: 700;
    line-height: 19px;
    margin-top: 12px;
    text-align: center;
  }
}

.rateIcon {
  width: 60px;
  height: 60px;
}

.rateReductionIcon {
  width: 55px;
  height: 60px;
}

.limitIcon {
  width: 60px;
  height: 60px;
}

.termIcon {
  width: 60px;
  height: 60px;
}

.repaymentIcon {
  width: 55px;
  height: 60px;
}

.link {
  text-decoration: underline;
}

@media (max-width: 1300px) {

  .section {
    margin-top: 36px;
    padding: 38px 72px 60px;
  }

  .title {
    font-size: 50px;
    line-height: 60px;
  }

  .caption {
    font-size: 28px;
    line-height: 34px;
    margin-top: 30px;
    margin-bottom: 0;
  }

  .topContainer {
    flex-direction: column;
    width: 100%;
    gap: 30px;
  }

  .fastImage {
    width: 576px;
    height: 462px;
  }

  .advantages {
    gap: 16px;
    flex-wrap: wrap;
  }

  .advantage {
    border: none;
    border-radius: 16px;
    width: calc(50% - 8px);
    padding: 40px 30px;

    &Icon {
      display: block;
      width: 90px;
      height: 90px;
    }

    &Title {
      font-size: 32px;
      line-height: 38px;
      margin-top: 30px;
      max-width: 200px;
    }

    &Text {
      font-size: 22px;
      line-height: 26px;
      margin-top: 22px;
    }

    &:last-of-type {
      border-right: none;
    }

    &:nth-of-type(1) {
      background-color: $CatskillWhite3;
    }

    &:nth-of-type(2) {
      background-color: $Casper;
    }

    &:nth-of-type(3) {
      background-color: $CardMainWhite;
    }

    &:nth-of-type(4) {
      background-color: $CatskillWhite3;
    }
  }

  .features {
    margin-top: 120px;
    flex-direction: column;
  }

  .feature {
    width: 100%;
    height: unset;
    flex-direction: row;
    gap: 64px;
    border-radius: unset;
    background-color: inherit;
    border-top: 1px solid $CasperLight;
    padding: 46px 46px 46px 0;

    &:last-of-type {
      border-bottom: 1px solid $CasperLight;
    }

    &Text {
      font-size: 32px;
      line-height: 38px;
      margin-top: 0;
      text-align: left;
    }
  }

  .iconContainer {
    width: 110px;
    height: 114px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .rateIcon {
    width: 108px;
    height: 108px;
  }

  .rateReductionIcon {
    width: 97px;
    height: 108px;
  }

  .limitIcon {
    width: 108px;
    height: 108px;
  }

  .termIcon {
    width: 106px;
    height: 106px;
  }

  .repaymentIcon {
    width: 98px;
    height: 111px;
  }
}

@media (max-width: 720px) {

  .section {
    margin: 0;
    padding: 38px 72px 30px;
  }

  .title {
    font-weight: 800;
    font-size: 54px;
    line-height: 65px;
  }

  .caption {
    font-weight: 400;
    font-size: 33px;
    line-height: 40px;
  }

  .topContainer {
    gap: 18px;
  }

  .imageContainer {
    width: unset;
    height: unset;
    justify-content: center;
  }

  .fastImage {
    width: 100%;
    height: 100%;
    max-width: 440px;
  }

  .advantages {
    margin-top: 60px;
    gap: 8px;
  }

  .advantage {
    padding: 18px 12px 10px;

    &Icon {
      width: 43px;
      height: 43px;
    }

    &Title {
      margin-top: 14px;
      font-size: 15px;
      line-height: 18px;
      max-width: 90px;
    }

    &Text {
      font-size: 10px;
      line-height: 12px;
      margin-top: 10px;
    }
  }

  .features {
    margin-top: 60px;
  }

  .feature {
    padding-right: 20px;
    gap: 30px;

    &Text {
      font-size: 15px;
      line-height: 18px;
    }
  }

  .iconContainer {
    width: 52px;
    height: 54px;
  }

  .rateIcon {
    width: 51px;
    height: 51px;
  }

  .rateReductionIcon {
    width: 46px;
    height: 51px;
  }

  .limitIcon {
    width: 51px;
    height: 51px;
  }

  .termIcon {
    width: 50px;
    height: 50px;
  }

  .repaymentIcon {
    width: 46px;
    height: 52px;
  }
}

@media (max-width: 600px){
  .section {
    padding: 38px 48px 30px;
  }

  .title {
    font-size: 48px;
    line-height: 1.3em
  }

  .caption {
    font-size: 33px;
    line-height: 1.2em;
  }
}

@media (max-width: 550px){
  .section {
    padding: 38px 32px 30px;
  }

  .title {
    font-size: 42px;
  }

  .caption {
    font-size: 24px;
  }
}

@media (max-width: 450px){
  .section {
    padding: 38px 28px 30px;
  }

  .title {
    font-size: 28px;
  }

  .caption {
    font-size: 18px;
  }
}
