@import '@src/styles/colors.scss';
@import '@src/styles/windows.scss';

.container {
  width: 100%;
  height: 110%;
  margin: 0 32px;
  display: flex;
  align-items: center;

  @include tablet {
    margin: 0 12px;
  }

  @include mobile {
    display: none;
  }
}

.list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
}
