@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.sign-up-steps-mobile {
  max-width: calc(100% + #{scaledPixels(144)});
  box-sizing: border-box;


  &__tabs {
    padding-left: scaledPixels(72);
    padding-right: scaledPixels(72);

    &__item {
      flex: 1;
      min-width: 40%;
      height: scaledPixels(130);
      text-align: center;
      align-items: center;
      display: flex;
      justify-content: center;
      background-color: $CatskillWhite3;
      border-radius: scaledPixels(10);
      font-weight: bold;
      font-size: scaledPixels(24);
      line-height: scaledPixels(30);

      &:nth-last-child(n+3) {
        margin-bottom: scaledPixels(16);
      }

      &:nth-child(2n) {
        margin-left: scaledPixels(8);
      }

      &:nth-child(2n + 1) {
        margin-right: scaledPixels(8);
      }
    }

    &__container {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__hint {
      display: flex;
      align-items: center;
      margin-top: scaledPixels(34);
      margin-bottom: scaledPixels(18);

      &__image {
        height: scaledPixels(45);
      }

      &__text {
        font-size: scaledPixels(22);
        line-height: scaledPixels(26);
        margin-left: scaledPixels(15);
        color: $Waterloo;
      }
    }
  }


  &__carousel {
    margin-right: scaledPixels(72);

    .slick-slider {
      padding-bottom: scaledPixels(98);
    }

    .slick-dots {
      bottom: 3.5vw;

      li {
        height: auto;
        width: auto;
        margin: 0;

        button {
          width: scaledPixels(12);
          height: scaledPixels(12);
          box-sizing: content-box;
          position: relative;
          padding: scaledPixels(10);

          &:before {
            transition: .3s;
            width: scaledPixels(12);
            height: scaledPixels(12);
            font-size: 0;
            border-radius: scaledPixels(6);
            background-color: $Casper;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%)
          }
        }

        &.slick-active {

          button {
            width: scaledPixels(42);

            &:before {
              width: scaledPixels(42);
            }
          }
        }
      }
    }

    &__item {
      text-align: left;
      width: scaledPixels(576) !important;
      height: scaledPixels(442);
      background-color: $Casper;
      border-radius: scaledPixels(10);
      padding-top: scaledPixels(40);
      padding-left: scaledPixels(30);
      padding-right: scaledPixels(30);
      box-sizing: border-box;

      &__header {
        justify-content: flex-start;
        margin-bottom: scaledPixels(46);
        position: relative;
      }

      &__image {
        height: scaledPixels(94);
      }

      &__title {
        font-size: scaledPixels(32);
        line-height: scaledPixels((38));
        color: $CloudBurst;
      }

      &__text {
        font-size: scaledPixels(24);
        line-height: scaledPixels(29);
        color: $CloudBurst;
      }

      &__close {
        width: scaledPixels(40);
        height: scaledPixels(40);
        background-color: $CloudBurst;
        border-radius: 50%;
        position: absolute;
        right: 0;
        top: 0;
        z-index: 1;

        &:before,
        &:after {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(45deg);
          border-radius: scaledPixels(2);
          background-color: $WhiteBase;
        }

        &:before {
          height: 60%;
          width: scaledPixels(2);
        }

        &:after {
          width: 60%;
          height: scaledPixels(2);
        }
      }
    }

    .slick-slide {
      transform: scale(0.81);
      transition: .3s;

      .sign-up-steps-mobile__carousel__item {
        transform: translateX(#{scaledPixels(27.28)});
        margin-bottom: 0;
      }

      &.slick-active {
        transform: scale(1);

        & ~ .slick-slide {

          .sign-up-steps-mobile__carousel__item {
            transform: translateX(#{scaledPixels(-27.28)});
          }
        }

        .sign-up-steps-mobile__carousel__item {
          transform: translateX(0);
        }
      }
    }
  }

  &__content-outer {
    box-sizing: border-box;
    max-width: 100%;
  }
}
