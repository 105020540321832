
@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/functions.scss';

.subnav {
  display: none;
  @media screen and (max-width: 720px) {
    display: flex;
  }
  position: fixed;
  bottom: 0;
  width: 100%;
  height: scaledPixels(150);
  background-color: $CardMainWhite;
  align-items: center;
  z-index: 1000;



  &__tags {
    margin: 0;
    padding: 0;
    display: flex;
    width: 100%;
    justify-content: space-evenly;

    .Button {
      background-color: transparent;
      color: $Waterloo;
      padding: 0;
    }
  }

  &__item {
    list-style-type: none;
    color: $Waterloo;
    width: 14%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
      font-size:  scaledPixels(19);
      margin-top: scaledPixels(9);
      text-align: center;
      color: $Waterloo;

    }
  }

  &__icon {
    height: scaledPixels(60);
    width: scaledPixels(60);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
