@import '@src/styles/colors.scss';
@import '@src/styles/mixins.scss';
@import '@src/styles/windows.scss';

.Input {
  $border-radius: 5px;

  display: flex;
  flex-direction: row;
  align-items: center;

  width: 100%;
  box-sizing: border-box;
  @include mobile{
    min-width: 65px;
  }

  &-Inline {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    flex-grow: 1;

    border: 0.5px solid $CasperLight;
    border-radius: $border-radius;
    background-color: $WhiteBase;
    padding: 6.5px 6px 7px 12px;

    @include tablet() {
      padding: 13px 17px;
    }

    @include mobile() {
      padding: 8px 11px;

      & .prefix {
        padding: 0;
      }

      & .input {
        padding: 0;
      }
    }

    &_heightFull {
      height: 100%;
      box-sizing: border-box;
    }

    &_has_right_element_outline {
      border-top-right-radius: 0px;
      border-bottom-right-radius: 0px;
    }

    &_has_left_element_outline {
      border-top-left-radius: 0px;
      border-bottom-left-radius: 0px;
    }

    span {
      flex-shrink: 0;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: #1C2A40;

      @include tablet() {
        @include text28;
        color: #1C2A40;
        //-webkit-text-fill-color: #1C2A40;
      }

      @include mobile() {
        @include text13;
      }
    }

    .Wrapper-Block {
      width: 100%;

      input {
        width: 100%;
        border: none;
        color: #1C2A40;

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        outline: none;
        box-sizing: border-box;

        &::placeholder {
          color: $BaliHai;
        }

        @include tablet() {
          background-color: inherit;
          //color: #1C2A40;
          //-webkit-text-fill-color: #1C2A40;
          padding-left: 0;

          @include text28
        }

        @include mobile() {
          @include text14
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active{
          -webkit-box-shadow: 0 0 0 0 #fff inset !important;
          transition: background-color 5000s ease-in-out 0s !important;
        }
      }
    }


    &_width {

      &_50 {
        max-width: 50px;
      }

      &_60 {
        max-width: 60px;
        @include tablet{
          max-width: 200px;
        }
        @include mobile{
          max-width: 95px;
        }
      }

      &_70 {
        max-width: 70px;
      }

      &_110 {
        max-width: 110px;
      }
    }

    &_error {
      border: solid $FrenchRose 0.5px;

      span {
        color: $FrenchRose;
      }

      input {
        color: $FrenchRose;
      }
    }

    &_disabled {
      background-color: $CasperLight;

      //@include tablet {
      //  background-color: inherit;
      //}

      span {
        color: $BaliHai;
        -webkit-text-fill-color: $BaliHai;

        @include tablet {
          color: #1C2A40;
          //-webkit-text-fill-color: #1C2A40;
        }
      }

      input {
        background-color: $CasperLight;
        color: $BaliHai;
        -webkit-text-fill-color: $BaliHai;
      }
    }

    .Right-Inline-Container {
      cursor: pointer;
      width: 15px;
      height: 15px;
      margin-right: 2px;
      @include tablet{
        width: 30px;
        height: 30px;
      }
      @include mobile{
        width: 15px;
        height: 15px;
      }

      * {
        height: 100%;
      }
    }

    .Error-Container, .Confirm-Container {
      width: 19px;
      height: 19px;
      margin-left: 10px;
      @include tablet{
        width: 30px;
        height: 30px;
      }

      @include mobile{
        width: 19px;
        height: 19px;
      }

      img {
        height: 100%;
      }
    }
  }

  .Left-Outline-Element-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    min-height: 35.5px;
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;

    transition: background-color 200ms;
    background-color: $CasperLight;

    &.Confirmed {
      background-color: $CloudBurst;
    }
  }

  .Right-Outline-Element-Container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 37px;
    min-height: 35.5px;
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    transition: background-color 200ms;
    background-color: $CasperLight;

    &.Confirmed {
      background-color: $CloudBurst;
    }
  }
}
